h2 {
    font-weight: 300;
}

img {
    width: 400px;
}

.left-container {
    margin: auto;
    position: absolute;
    width: 50%;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 10px;
}