.cta {
    grid-template-columns: 1fr 1fr;
    margin-top: 2.5rem;
    display: grid;
    gap: 1.2rem;
    justify-content: center;
}

.enquire {
    margin-left: auto;
    margin-right: 0;
}

.review {
    margin-left: 0;
    margin-right: auto;
}

.quote, .services {
    padding: 2rem 1rem;
}

.services-list {
    list-style: disc;
    list-style-type: disc;
}

.box > p {
    padding: 0 0 2rem 0;
}


@media screen and (max-width: 1024px) {

    .home-container {
        width: 100%;
    }

}

@media screen and (max-width: 610px) {

    .cta {
        grid-template-columns: 1fr;
        gap: 0.5rem;
    }

    .enquire {
        margin-left: auto;
        margin-right: auto;
    }
    
    .review {
        margin-left: auto;
        margin-right: auto;
    }

}