/* ======================== START ======================== */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

/* ============ SET DEFAULTS TO 0 ============ */
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

/* ============ DEFINE VARIABLES ============ */

:root {

    /* ===== COLOR SCHEMES ===== */

    /* == STRIKING AND SIMPLE == */
    --color-bg: #F0EEED;
    --color-bg-variant: #609EA2;
    --color-primary: #019544;
    --color-primary-variant: #333333;
    --color-white: rgb(255, 255, 255);
    --color-light: #F5F5F5;

    --color-danger: #A24549;
    --color-success: #77A245;


    /* =====  END COLOR SCHEMES ===== */

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

/* ============ MAIN BODY STYLES ============ */

html {
    scroll-behavior: smooth;
}

/* Disable Chrome Scrollbar */
::-webkit-scrollbar {
    display: none;
}

/* Disable scrollbar Firefox */
@-moz-document url-prefix() {
    html{
      scrollbar-width: none;
    }
  }

body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-dark);
    line-height: 1.7;
    /* background-image: url(../src/assets/bg-texture.png) */
}

/* ========= Main Page Layout ========= */

.main-grid {
    grid-template-columns: repeat(2, 1fr);
}

/* ====== Global Elements ======= */

/* Content areas / boxes */
.content {
    margin: auto;
    padding: 2rem 1rem;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.box {
    position: relative;
    margin: 2rem 0;
    padding: 1rem 2rem;
    border-radius: 1rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
    background-color: var(--color-light);
}

/* Text */
.box > h2 {
    font-weight: 300;
    padding: 1rem 1rem;
}

.subheading {
    padding: 0.5rem 0;
    font-weight: 600;
}


/* Buttons and links */
a {
    color: var(--color-primary);
    transition: var(--transition);
    cursor: pointer;
}

a:hover {
    color: var(--color-primary-variant);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.5rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-primary-variant);
    border: block;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-white);
}

.btn.rounded {
    border-radius: 2rem;
}


/* ============ MEDIA QUERIES (MEDIUM DEVICES) ============ */

@media screen and (max-width: 1024px) {

    * {
        overflow-x: hidden;
    }

    .main-grid {
        grid-template-columns: 1fr;
    }

    .left-pannel {
        display: none;
    }

}



/* ============ MEDIA QUERIES (SMALL DEVICES) ============ */

@media screen and (max-width: 600px) {

    .main-grid {
        grid-template-columns: 1fr;
    }

    .left-pannel {
        display: none;
    }

}

@media screen and (max-width: 500px) {

    .box {
        grid-template-columns: 1fr;
        padding: 1rem 1rem;
    }

}



/* ======================== END ======================== */