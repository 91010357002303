.form {
    /* display: none; */
}


/* Grid Styling */
.reviews {
    margin: 0;
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
}

.review-item {
    margin: 0 10px;
    padding: 0;
}


.review-name {
    padding: 10px 0 0;
    font-size: 20px;
    font-weight: 400;
}

.box > h2.review-title {
    font-size: 26px;
    font-weight: 500;
}

.review-body {
    font-weight: 300;
}

.stars {
    margin: 0;
}


/* ============ MEDIA QUERIES (MEDIUM DEVICES) ============ */

@media screen and (max-width: 1024px) {

    /* Grid Styling */
    .reviews {
        grid-template-columns: 1fr;
    }

}