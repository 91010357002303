nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-light);
    padding: 40px 80px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
}

img.navbar-image {
    width: 150px;
}

#navbar {
    display: flex;
    align-items: center;
    justify-content: center;
}

#navbar li {
    padding: 0 20px;
    position: relative;
}

#navbar li a {
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--color-primary-variant);
    transition: var(--transition);
}

#navbar li a:hover,
#navbar li a.active {
    color: var(--color-primary);
    
}

#navbar li a:hover::after,
#navbar li a.active::after {
    content: "";
    width: 30%;
    height: 2px;
    background: var(--color-primary);
    position: absolute;
    bottom: -4px;
    left: 20px;
}

#mobile {
    display: none;
}

#mobile i {
    color: var(--color-primary-variant);
}

@media screen and (max-width: 1024px) {

    #navbar {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 25vh;
        right: 0;
        width: fit-content;
        height: fit-content;
        background: var(--color-light);
        box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
        padding: 40px 0 0 10px;
        border-radius: 3rem 0 0 3rem;
        transition: 0.3s ease-in-out;
        padding-bottom: 10%;
        z-index: 9999;
        transform: translateX(100%);
    }
    
    #navbar.active {

        transform: translateX(0);

    }
    #navbar li {

        margin-bottom: 10px;

    }

    #mobile {

        display: block;

    }

    #mobile i {

        font-size: 24px;
        cursor: pointer;

    }

}

@media screen and (max-width: 500px) {

    nav {

        padding: 10px 30px;

    }

}