.gallery-box {
    padding: 1rem;
}

.image-view {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background: black;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.image-view > img {
    width: auto;
    max-width: 85%;
    max-height: 90%;
}

.image-view > button {
    cursor: pointer;
    position: flex;
    width: 2rem;
    height: 2rem;
    color: var(--color-white);
    background: transparent;
    font-size: 2rem;
}

.close {
    position: absolute;
    top: 2rem;
    right: 2rem;
}

.image-view > .arrow {
    margin: 1rem;
}


/* ============ MEDIA QUERIES (MEDIUM DEVICES) ============ */
@media screen and (max-width: 1024px) {}


/* Customer widths to accomodate for the full screen image viewer */
@media screen and (max-width: 850px) {

    .image-view > img {
        width: auto;
        max-width: 80%;
        max-height: 90%;
    }

}
@media screen and (max-width: 750px) {

    .image-view {
        display: none;
    }

}



/* ============ MEDIA QUERIES (SMALL DEVICES) ============ */

@media screen and (max-width: 600px) {}