.box > h3 {
    font-weight: 300;
}

.box > ul {
    padding: 1.5rem 0;
    justify-content: center;
}

.box > ul > li {
    padding: 0 1rem;
    font-size: 1.5rem;
}

i {
    color: var(--color-primary);
    font-size: 1.5rem;
    margin: 1rem;
}

.box > ul > li {
    padding: 0.5rem 3rem;
}

form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

form > input {
    font-family: 'Poppins';
    height: 3rem;
    padding: 2rem;
    border-radius: 1rem;
    width: 100%;
}

form > textarea {
    width: 100%;
    font-family: 'Poppins';
    padding: 2rem;
    border-radius: 1rem;
}


@media screen and (max-width: 650px) {

    .box > ul {
        display: grid;
    }

    .box > ul > li {
        padding: 0.5rem 0;
    }

}